import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { addConversar } from '../../../redux/actions/conversar';

import { makeStyles } from '@material-ui/core/styles';

import Loader from '../../../../../components/Loader';
import Form from '../../../components/Form';
import PageHeader from '../../../components/PageHeader';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
}));

const initialConversar = {
  name: '',
  topics: [],
  image: '',
  video: '',
  englishVideo: '',
  englishThumbnail: '',
  spanishVideo: '',
  spanishThumbnail: '',
  script: '',
  order: 0,
};

export default function NewConversar({ name }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { actionLoading } = useSelector(({ admin }) => admin.conversar);
  const [conversarData, setConversarData] = useState(initialConversar);

  const handleSave = (d) => {
    setConversarData(d);
    dispatch(addConversar(d));
  };

  return (
    <div className={classes.root}>
      {actionLoading && <Loader fetching={actionLoading} />}
      <PageHeader disableSearch title={name} back />
      <Form
        initialData={conversarData}
        showTopicPicker
        content={[
          'englishVideo',
          'englishThumbnail',
          'spanishVideo',
          'spanishThumbnail',
          'video',
          'image',
          'script',
        ]}
        onSave={handleSave}
      />
    </div>
  );
}
